import { useLandingPage } from 'hooks/useLandingPage';
import Image from 'next/image';
import heroImage from 'public/assets/images/BoilerChatWide.webp';
import plumberHeroImage from 'public/assets/images/PlumberHero.webp';

import { StrongText } from 'components/StrongText';
import { JOB_TYPES } from 'utils/jobTypes';
import { getPriceFromJobType } from 'utils/tracking';

import { TickCircle } from '../MarketingSection/content/RepairCard';
import { Tracker } from '../Tracker';
import { renderPrice } from '../Tracker/Tracker';
import { Horizontal } from '../TrustBox/TrustBox';
import { HeroStyles as El } from './Hero.styles';

export const Hero = () => {
  const {
    isRepair,
    isPlumbing,
    setShouldAnimateExit,
    jobType,
    managedRates,
    isKantanPartner,
    isOVOPartner,
  } = useLandingPage();

  const getTitle = () => {
    if (isOVOPartner) {
      switch (jobType) {
        case JOB_TYPES.HEATING_REPAIR:
          return 'Book Your Boiler Repair In Minutes';
        case JOB_TYPES.PLUMBING_REPAIR:
          return 'Book Your Plumbing Repair In Minutes';
        case JOB_TYPES.GAS_SAFETY_CERTIFICATE:
          return 'Get Your Gas Safety Check The Easy Way';
        case JOB_TYPES.BOILER_SERVICE_COMBO:
          return 'Book a Heating Engineer The Easy Way';
        case JOB_TYPES.BOILER_SERVICE:
        default:
          return (
            <>
              Get Your <span>Boiler Serviced</span> The Easy Way
            </>
          );
      }
    }

    switch (jobType) {
      case JOB_TYPES.HEATING_REPAIR:
        return 'Book Your Boiler Repair In Minutes';
      case JOB_TYPES.PLUMBING_REPAIR:
        return 'Book Your Plumbing Repair In Minutes';
      case JOB_TYPES.BOILER_SERVICE_COMBO:
        return (
          <>
            Book a Heating <span>Engineer</span> The Easy Way
          </>
        );
      case JOB_TYPES.GAS_SAFETY_CERTIFICATE:
        return 'Get Your Gas Safety Check The Easy Way';
      default:
        return (
          <>
            Book your <span>Boiler Service</span> The Easy Way
          </>
        );
    }
  };

  const getDescription = () => {
    if (isOVOPartner) {
      switch (true) {
        case isRepair && !isPlumbing:
          return (
            <>
              If you are an OVO HomePlan customer and you pay your insurance
              monthly, please book your repair by logging into your account{' '}
              <El.ExternalLink href="https://www.ovoenergy.com/boiler-cover">
                here
              </El.ExternalLink>
            </>
          );
        case isPlumbing:
          return '';
        default:
          return (
            <>
              If you are an OVO HomePlan customer and you pay your insurance
              monthly, please book your service by logging into your account{' '}
              <El.ExternalLink href="https://www.ovoenergy.com/boiler-cover">
                here
              </El.ExternalLink>
            </>
          );
      }
    }

    switch (jobType) {
      case JOB_TYPES.PLUMBING_REPAIR:
      case JOB_TYPES.HEATING_REPAIR:
        return <>The easy way to get your boiler fixed</>;
      case JOB_TYPES.GAS_SAFETY_CERTIFICATE:
      case JOB_TYPES.BOILER_SERVICE_COMBO:
      default:
        return (
          <>
            The hassle-free boiler service that helps prolong the lifespan of
            your boiler & <StrongText>save money on your gas bill</StrongText>.{' '}
          </>
        );
    }
  };

  const title = getTitle();

  const description = getDescription();

  return (
    <El.Hero>
      <El.Container>
        <El.Half>
          {!isKantanPartner && !isPlumbing && (
            <El.SubTitle>Over 200,000 boilers serviced in 2022¹</El.SubTitle>
          )}
          <El.Title $isKantan={isKantanPartner}>{title}</El.Title>
          <El.DesktopDescription>
            <Description description={description} />
          </El.DesktopDescription>
        </El.Half>
        <El.Half>
          {isPlumbing ? (
            <El.ImageWrap $isPlumbing={isPlumbing}>
              <El.PlumbingImageWrap>
                <Image
                  src={plumberHeroImage}
                  alt={'Plumber'}
                  fill={true}
                  priority={true}
                />
              </El.PlumbingImageWrap>
            </El.ImageWrap>
          ) : (
            <El.ImageWrap $isKantan={isKantanPartner}>
              <Image
                src={heroImage}
                alt={'Boiler engineer'}
                fill={true}
                priority={true}
              />
            </El.ImageWrap>
          )}
          <El.Cost $isKantan={isKantanPartner}>
            <El.PricePrefix>{isRepair ? 'From' : 'Just'}</El.PricePrefix>
            <El.Price>
              {renderPrice({
                managedRates,
                isRepair,
                jobType,
                isNewLanding: true,
                showPerHour: isRepair,
              })}
            </El.Price>
            <El.VAT>
              {getPriceFromJobType(jobType, managedRates).isAmountWithVat
                ? 'inc VAT'
                : 'exc VAT'}
            </El.VAT>
          </El.Cost>
        </El.Half>
      </El.Container>
      <El.TrackerContainer>
        <Tracker
          showPrice
          animationTriggerCallback={setShouldAnimateExit}
          simplified
        />
      </El.TrackerContainer>
      <El.MobileDescription>
        <Description description={description} />
      </El.MobileDescription>
      {isKantanPartner && <Horizontal />}
    </El.Hero>
  );
};

const Description = ({
  description,
}: {
  description: React.ReactElement | string;
}) => {
  const { getDescriptionListItems } = useLandingPage();
  return (
    <>
      <El.Description>{description}</El.Description>
      <El.List>
        {getDescriptionListItems().map((listItem, index) => (
          <DescriptionListItem key={index} listItem={listItem} />
        ))}
      </El.List>
    </>
  );
};

const DescriptionListItem = ({ listItem }: { listItem: string }) => {
  return (
    <li>
      <El.IconWrap>
        <TickCircle />
      </El.IconWrap>
      {listItem}
    </li>
  );
};
