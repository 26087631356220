import Image from 'next/image';

import { getSupportInfoFromPartner } from 'utils/partners';

import { NewSupportStyles as El } from './NewSupport.styles';
interface NewSupportProps {
  partner?: string;
}

export const NewSupport = ({ partner }: NewSupportProps) => {
  const { emailAddress, phoneNumber, phoneNumberText } =
    getSupportInfoFromPartner(partner);
  const isKantan = partner === 'KANTAN';
  const lowercasePartner = partner?.toLowerCase();

  return (
    <El.Container data-testid="Newsupport">
      <El.ContactContainer>
        <El.ContactBox>
          <div>
            <Image
              src={`/assets/svg/${lowercasePartner}/LetterIcon.svg`}
              alt="Email us"
              width={isKantan ? 36 : 48}
              height={isKantan ? 36 : 48}
            />
            <El.ContactTitle>Email us</El.ContactTitle>
          </div>
          <El.ContactLink
            href={`mailto: ${emailAddress}`}
            target="_blank"
            rel="noreferrer"
          >
            {emailAddress}
          </El.ContactLink>
          <El.OpeningTimes>
            Response times within 24 hours (Mon-Fri)
          </El.OpeningTimes>
        </El.ContactBox>
      </El.ContactContainer>
    </El.Container>
  );
};
