import { useTheme } from '@emotion/react';
import { useLandingPage } from 'hooks/useLandingPage';
import { useRouter } from 'next/router';

import { KantanLogo } from 'components/redesign/KantanLogo';
import { OvoLogo } from 'components/redesign/OvoLogo';
import {
  getSupportInfoFromPartner,
  getTrademarkLinksFromPartner,
} from 'utils/partners';
import { isBeyond } from 'utils/urls';

import { FooterStyles as El } from './Footer.styles';

interface FooterProps {
  productType?: string;
  isKantanPartner?: boolean;
  partner?: string;
}

export const Footer = ({
  productType,
  isKantanPartner,
  partner,
}: FooterProps) => {
  const router = useRouter();
  const theme = useTheme();
  const { isOVOPartner } = useLandingPage();
  const trademarkLinks = getTrademarkLinksFromPartner(
    partner,
    isBeyond(router.asPath),
  );
  const termsAndConditionsUrl = trademarkLinks.find(
    (link) => link.title === 'Terms',
  )?.url;
  const { termsAndConditionsCopy } = getSupportInfoFromPartner(partner);
  const year = new Date().getFullYear();
  return (
    <El.Container>
      <El.TermsText $isOVO={isOVOPartner}>
        <p data-testid="termsAndConditions">
          {termsAndConditionsCopy}. Engineers are automatically assigned to a
          job request. They may call a customer to rearrange an appointment
          after booking. After the job is completed, customers receive an email
          with payment instructions. Cash is not accepted. See full terms and
          conditions{' '}
          <a href={termsAndConditionsUrl} target="_blank" rel="noreferrer">
            here
          </a>
          .
        </p>
        {productType === 'service' && isKantanPartner && (
          <>
            <br />
            <p data-testid="serviceInformation">
              1. When the service is completed, you’ll receive an email with
              your invoice and payment link attached. Once payment is received,
              your digital Certificate will be issued.
            </p>
          </>
        )}
      </El.TermsText>
      <El.BottomRow>
        <El.Logo>
          {isKantanPartner ? (
            <KantanLogo
              color={theme.app.messages.action}
              width={'83'}
              height={'20'}
            />
          ) : (
            <OvoLogo
              color={theme.app.messages.logo}
              width={'83'}
              height={'20'}
            />
          )}
        </El.Logo>
        <El.CompanyInfoAndLinks>
          <div>
            <p data-testid="copyright">
              &copy; {year} {isKantanPartner ? 'Kantan' : 'OVO Energy'}, All
              rights reserved ·{' '}
            </p>
            {trademarkLinks.map((link) => (
              <p key={link.title}>
                <a href={link.url} target="_blank" rel="noreferrer">
                  {link.title}
                </a>{' '}
                ·
              </p>
            ))}
          </div>
          <p className="company-info">
            Company number 11681210 · Registered in England &amp; Wales
          </p>
        </El.CompanyInfoAndLinks>
      </El.BottomRow>
    </El.Container>
  );
};
