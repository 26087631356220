import { useLandingPage } from 'hooks/useLandingPage';

import { SimpleStepsStyles as El } from './SimpleSteps.styles';

const serviceSteps = (isGas?: boolean) => {
  const serviceType = isGas ? 'gas safety check' : 'service';
  const checkType = isGas ? 'required checks' : 'service';

  return [
    {
      title: `Book your ${serviceType} online in just a few clicks.`,
      description: 'Select the time and date that suits you best.',
    },
    {
      title: `Your engineer will carry out the ${checkType}.`,
      description: "They'll be thorough, professional and considerate.",
    },
    {
      title: 'And there you have it, a safer, more efficient boiler.',
      description: `We'll send you an official Service Certificate by email after payment.`,
    },
  ];
};

const repairSteps = (isPlumbing: boolean) => [
  {
    title: 'Book your repair online in just a few clicks.',
    description: 'Select the time and date that suits you best.',
  },
  {
    title: `Your ${
      isPlumbing ? 'plumber' : 'engineer'
    } will carry out the repair`,
    description: "They'll be thorough, professional and considerate.",
  },
  {
    title: 'And there you have it, normality restored!',
    description: `We'll even guarantee the workmanship for 1 year.`,
  },
];

export const SimpleSteps = () => {
  const { isGas, isRepair, isPlumbing, isOVOPartner } = useLandingPage();

  const steps = isRepair ? repairSteps(isPlumbing) : serviceSteps(isGas);

  return (
    <El.Container>
      {steps.map((step, index) => {
        return (
          <El.Step key={index}>
            <El.StepNumber $isOVO={isOVOPartner}>{index + 1}</El.StepNumber>
            <El.StepTitle>{step.title}</El.StepTitle>
            <El.StepDescription>{step.description}</El.StepDescription>
          </El.Step>
        );
      })}
      <El.Call $isOVO={isOVOPartner}>
        <p>
          {!isOVOPartner &&
            `You'll get a call when your engineer is on their way.`}
        </p>
      </El.Call>
      <El.Pay $isOVO={isOVOPartner}>
        <p>{!isOVOPartner && `You'll only pay once the work is complete.`}</p>
      </El.Pay>
    </El.Container>
  );
};
