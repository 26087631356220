import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: ${({ theme }) => theme.app.margin.extraExtraLargeMargin} auto 0;
  gap: ${({ theme }) => theme.app.margin.largeMargin};
  text-align: center;
  color: ${({ theme }) => theme.app.landingPage.generalText.major};

  @media (${mediaQuery('desktop')}) {
    flex-direction: row;
  }
`;

const Step = styled.div`
  max-width: 250px;
  margin: 0 auto;

  &:first-of-type {
    order: 1;
  }
  &:nth-of-type(2) {
    order: 3;
  }
  &:nth-child(3) {
    order: 5;
  }
`;

const StepNumber = styled.p<{ $isOVO?: boolean }>`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[32]};
  line-height: 1.2;
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  color: ${({ $isOVO, theme }) =>
    $isOVO ? theme.app.messages.action : theme.app.messages.actionDark};
  margin-bottom: ${({ theme }) => theme.app.margin.smallMargin};

  @media (${mediaQuery('desktop')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[42]};
  }
`;

const StepTitle = styled.h3`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  line-height: 1.3;
  margin: 0 auto ${({ theme }) => theme.app.margin.mediumLargeMargin};

  @media (${mediaQuery('desktop')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  }
`;

const ExtraInfo = styled.div`
  align-self: flex-end;
  color: ${({ theme }) => theme.app.messages.action};
  margin: 0 auto;
  position: relative;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};

  p {
    max-width: 270px;
    min-width: 150px;
    width: 100%;
    margin: 0 auto;

    &::before {
      content: '';
      position: absolute;
      width: 100px;
      height: 40px;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(90deg);
      top: 50%;
      margin-top: -20px;
    }
  }

  @media (${mediaQuery('desktop')}) {
    p {
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};

      &::before {
        transform: none;
        left: 0;
        right: 50%;
        width: 100%;
        top: -${({ theme }) => theme.app.margin.extraLargeMargin};
      }
    }
  }
`;

const Call = styled(ExtraInfo)<{ $isOVO?: boolean }>`
  order: 2;
  color: ${({ $isOVO, theme }) =>
    $isOVO ? theme.app.messages.action : theme.app.messages.actionDark};
  padding-bottom: ${({ theme, $isOVO }) =>
    $isOVO ? '100px' : theme.app.padding.mediumLargePadding};

  p {
    padding-left: 150px;

    &::before {
      background-image: ${({ $isOVO }) =>
        $isOVO
          ? 'url(/assets/svg/ovo/ArrowUnderIcon.svg)'
          : 'url(/assets/svg/kantan/ArrowUnderIcon.svg)'};
      left: ${({ $isOVO }) => ($isOVO ? '20px' : '80px')};
    }
  }

  @media (${mediaQuery('desktop')}) {
    p {
      padding: 0;

      &::before {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;

const Pay = styled(ExtraInfo)<{ $isOVO?: boolean }>`
  order: 4;
  padding-bottom: ${({ theme, $isOVO }) =>
    $isOVO ? '100px' : theme.app.padding.mediumLargePadding};
  color: ${({ $isOVO, theme }) =>
    $isOVO ? theme.app.messages.action : theme.app.messages.actionDark};

  p {
    padding-right: 150px;

    &::before {
      right: ${({ $isOVO }) => ($isOVO ? '30px' : '100px')};
      background-image: ${({ $isOVO }) =>
        $isOVO
          ? 'url(/assets/svg/ovo/ArrowOverIcon.svg)'
          : 'url(/assets/svg/kantan/ArrowOverIcon.svg)'};
    }
  }

  @media (${mediaQuery('desktop')}) {
    p {
      padding: 0;

      &::before {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;

const StepDescription = styled.p`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
  line-height: 1.3;
`;

const SimpleStepsStyles = {
  Container,
  Step,
  StepNumber,
  StepTitle,
  StepDescription,
  Call,
  Pay,
};

export { SimpleStepsStyles };
