import { useLandingPage } from 'hooks/useLandingPage';
import Image from 'next/image';

import { GridIconsStyles as El } from './GridIcons.styles';

export const GridIcons = () => {
  const { partner, isKantanPartner, isOVOPartner } = useLandingPage();

  const icons = isOVOPartner ? iconListOVO : iconList;

  return (
    <El.Container $fullVersion={!isOVOPartner}>
      {icons.map((icon) => {
        return (
          <El.IconText key={icon.icon}>
            <El.ImageWrap>
              <Image
                src={`/assets/svg/${partner?.toLocaleLowerCase()}/${
                  icon.icon
                }.svg`}
                alt={`${icon.alt} Icon`}
                width={isKantanPartner ? icon.width : 38}
                height={isKantanPartner ? icon.height : 38}
              />
            </El.ImageWrap>
            <p>{icon.text}</p>
          </El.IconText>
        );
      })}
    </El.Container>
  );
};

const iconListOVO = [
  {
    icon: 'UsageIcon',
    alt: 'Usage',
    text: 'Better efficiency could help save money on your gas bills',
    width: 30,
    height: 40,
  },
  {
    icon: 'BoilerIcon',
    alt: 'Boiler',
    text: 'Could extend the life of your boiler and help to keep it running smoothly',
    width: 33,
    height: 26,
  },
  {
    icon: 'GasWarningIcon',
    alt: 'Gas Warning',
    text: 'Helps prevent future breakdowns and emergencies',
    width: 38,
    height: 38,
  },
  {
    icon: 'ProtectedHomeIcon',
    alt: 'Protected Home',
    text: 'Important for the safety of your home',
    width: 38,
    height: 33,
  },
];

const iconList = [
  {
    icon: 'UsageIcon',
    alt: 'Usage',
    text: 'Better efficiency, saving you money on your gas bills',
    width: 30,
    height: 40,
  },
  {
    icon: 'RadiatorIcon',
    alt: 'Radiator',
    text: 'Better heating for your home, so no more cold radiators',
    width: 23,
    height: 31,
  },
  {
    icon: 'BoilerIcon',
    alt: 'Boiler',
    text: 'Extends the life of your boiler and helps to keep it running smoothly',
    width: 33,
    height: 26,
  },
  {
    icon: 'GasWarningIcon',
    alt: 'Gas Warning',
    text: 'Helps prevent future breakdowns and emergencies',
    width: 38,
    height: 38,
  },
  {
    icon: 'ProtectedHomeIcon',
    alt: 'Protected Home',
    text: 'Important for the safety of your home',
    width: 38,
    height: 33,
  },
  {
    icon: 'ShieldIcon',
    alt: 'Shield',
    text: 'Required for most manufacturers warranties',
    width: 23,
    height: 23,
  },
];
