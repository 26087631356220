import { StrongText } from 'components/StrongText';
import { JOB_TYPES } from 'utils/jobTypes';

export const REVIEWS = {
  reviewOne: {
    text(partnerCopyShort: string, jobType: string) {
      switch (jobType) {
        case JOB_TYPES.PLUMBING_REPAIR:
          return (
            <p>
              &quot;The service I received was{' '}
              <StrongText>fantastic</StrongText>. The plumber{' '}
              <StrongText>arrived on time</StrongText>, did a really good job,
              was polite and friendly.{' '}
              <StrongText>I would definitely recommend</StrongText> this
              company.&quot;
            </p>
          );
        default:
          return (
            <p>
              &quot;We spent over 2 weeks ringing Gas safe registered engineers{' '}
              <StrongText>only to be let down</StrongText> in one way or another
              and when we did manage to extract a price from them, it was
              horrendous.{' '}
              <StrongText>
                {partnerCopyShort} were much better in every way
              </StrongText>
              .&quot;
            </p>
          );
      }
    },
    name(jobType: string) {
      switch (jobType) {
        case JOB_TYPES.PLUMBING_REPAIR:
          return 'Barry Cheal';
        default:
          return 'Lawrence R.';
      }
    },
  },
  reviewTwo: {
    text(jobType: string) {
      switch (jobType) {
        case JOB_TYPES.PLUMBING_REPAIR:
          return (
            <p>
              &quot;Plumber turned up and was{' '}
              <StrongText>very friendly and polite</StrongText>. Bill and
              certificate were emailed to me after{' '}
              <StrongText>paying easily online</StrongText> too!&quot;
            </p>
          );
        case JOB_TYPES.HEATING_REPAIR:
          return (
            <p>
              &quot;More than happy to give praise where praise is due.{' '}
              <StrongText>
                Simple process of applying for a repair visit
              </StrongText>
              . On the day the engineer rang to say would it be ok if he arrived
              2 hours early. Faulty part located, new one sourced and{' '}
              <StrongText>fitted within two hours</StrongText>. I was expecting
              an extra two days.{' '}
              <StrongText>Life’s back to normal again</StrongText>, 40 degrees
              and a shower!&quot;
            </p>
          );
        default:
          return (
            <p>
              &quot;Excellent service, great communication and really easy to
              use. From booking online to paying for the service,{' '}
              <StrongText>everything was so simple</StrongText>. Would
              definitely recommend and use again!&quot;
            </p>
          );
      }
    },
    name(jobType: string) {
      switch (jobType) {
        case JOB_TYPES.PLUMBING_REPAIR:
          return 'Simon R.';
        default:
          return 'Sam W.';
      }
    },
  },
  reviewThree: {
    text() {
      return (
        <p>
          &quot;The engineer who came round to my house was{' '}
          <StrongText>
            professional, on-time and seemed very knowledgeable
          </StrongText>
          . He phoned ahead of time to check I was in and confirm parking
          arrangements.{' '}
          <StrongText>
            Overall a very efficient and easy to use service
          </StrongText>
          .&quot;
        </p>
      );
    },
    name() {
      return 'Hannah W.';
    },
  },
  reviewFour: {
    text(jobType: string) {
      switch (jobType) {
        case JOB_TYPES.PLUMBING_REPAIR:
          return (
            <p>
              &quot;Plumber was <StrongText>first class</StrongText>. Really{' '}
              <StrongText>efficient</StrongText> job&quot;
            </p>
          );
        default:
          return (
            <p>
              &quot;<StrongText>Engineer was on time</StrongText>, rang me
              beforehand as requested. Did a great job, and{' '}
              <StrongText>pointed out a really major fault</StrongText> that
              none of my other gas safety check people has ever picked up on.
              Spot on.&quot;
            </p>
          );
      }
    },
    name(jobType: string) {
      switch (jobType) {
        case JOB_TYPES.PLUMBING_REPAIR:
          return 'Malcolm J.';
        default:
          return 'Hannah S.';
      }
    },
  },
  reviewFive: {
    text(jobType: string) {
      switch (jobType) {
        case JOB_TYPES.PLUMBING_REPAIR:
          return (
            <p>
              &quot;Very <StrongText>efficient service</StrongText>. Simon was
              excellent.&quot;
            </p>
          );
        default:
          return (
            <p>
              &quot;Hi I would like to thank Terry and the Kantan team for the
              quick and swift way to help me out with my faulty boiler.{' '}
              <StrongText>
                Your online booking was so easy to arrange
              </StrongText>
              . I am absolutely amazed. All done in one day… perfect. Please
              accept the thanks from my wife…{' '}
              <StrongText>she hates cold showers</StrongText>. Thanks
              again.&quot;
            </p>
          );
      }
    },
    name(jobType: string) {
      switch (jobType) {
        case JOB_TYPES.PLUMBING_REPAIR:
          return 'Nikki K.';
        default:
          return 'Hannah S.';
      }
    },
  },
};
