import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  color: ${({ theme }) => theme.app.text.secondary};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  h3 {
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.regular};
    margin-bottom: ${({ theme }) => theme.app.margin.largeMargin};
    line-height: 1.3;

    @media (${mediaQuery('desktop')}) {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }

  a {
    color: ${({ theme }) => theme.app.messages.action};
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
    line-height: 1.3;
    position: relative;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  @media (${mediaQuery('desktop')}) {
    flex-direction: row;
    text-align: left;
  }
`;

const RepairBannerStyles = {
  Container,
};

export { RepairBannerStyles };
