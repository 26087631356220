import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

const Container = styled.div`
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.app.landingPage.generalText.major};
`;

export const Stars = styled.div`
  margin-bottom: ${({ theme }) => theme.app.margin.largeMargin};
`;

export const StarContainer = styled.div`
  margin: 0 ${({ theme }) => theme.app.margin.mediumMargin};
  display: inline-block;
  width: 24px;

  @media (${mediaQuery('tablet')}) {
    width: 48px;
  }
`;

const Quote = styled.blockquote`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  line-height: 1.3;
  margin-bottom: ${({ theme }) => theme.app.margin.largeMargin};
  text-align: center;

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  }
`;

const Cite = styled.cite`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  font-style: normal;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  }
`;

const IndividualReviewStyles = {
  Container,
  Stars,
  Quote,
  Cite,
  StarContainer,
};

export { IndividualReviewStyles };
