import { useLandingPage } from 'hooks/useLandingPage';
import Image from 'next/image';
import boilerChatImage from 'public/assets/images/BoilerChat.webp';
import plumberHeroImage from 'public/assets/images/PlumberHero.webp';
import tickCircleImageKantan from 'public/assets/svg/kantan/TickCircle.svg';
import tickCircleImageOVO from 'public/assets/svg/ovo/TickCircle.svg';

import { renderPrice } from 'components/redesign/Tracker/Tracker';
import { StrongText } from 'components/StrongText';
import { JOB_TYPES } from 'utils/jobTypes';

import { RepairCardStyles as El } from './RepairCard.styles';

const TITLE: Partial<Record<JOB_TYPES, string>> = {
  [JOB_TYPES.HEATING_REPAIR]: 'Boiler Repair',
  [JOB_TYPES.PLUMBING_REPAIR]: 'Plumbing Repair',
};

export const TickCircle = () => {
  const { isKantanPartner } = useLandingPage();
  return (
    <Image
      src={isKantanPartner ? tickCircleImageKantan : tickCircleImageOVO}
      alt="Boiler Repair"
      width={20}
      height={20}
    />
  );
};

export const RepairCard = () => {
  const {
    isRepair,
    isPlumbing,
    jobType,
    managedRates,
    isKantanPartner,
    isOVOPartner,
  } = useLandingPage();

  return (
    <El.Container>
      <El.Card>
        {isPlumbing ? (
          <El.PlumbingImageWrapper>
            <Image
              src={plumberHeroImage}
              alt="Plumbing Repair"
              width={320}
              height={400}
              priority={true}
            />
          </El.PlumbingImageWrapper>
        ) : (
          <Image
            src={boilerChatImage}
            alt="Boiler Repair"
            width={320}
            height={400}
            priority={true}
          />
        )}
        <div>
          <El.Title $isKantan={isKantanPartner}>
            {TITLE[jobType as JOB_TYPES]}
          </El.Title>
          <El.Price $isKantan={isKantanPartner}>
            {renderPrice({
              managedRates,
              isRepair,
              jobType,
              isNewLanding: true,
            })}
            <span> {isOVOPartner ? 'hourly rate' : 'per hour'}</span>
          </El.Price>
          <El.Vat>
            including VAT. {isOVOPartner && 'Additional charges may apply.'}
          </El.Vat>

          <El.List>
            <li>
              <El.TickCircleContainer>
                <TickCircle />
              </El.TickCircleContainer>
              <p>
                All the work is guaranteed for{' '}
                <StrongText>12 months</StrongText>
              </p>
            </li>
            <li>
              <TickCircle />
              <p>
                {isOVOPartner ? (
                  <>
                    Your {isPlumbing ? 'plumber' : 'engineer'} will always try
                    to fix the issue
                    <StrongText> within 1 hour</StrongText>
                  </>
                ) : (
                  <>
                    Your {isPlumbing ? 'plumber' : 'engineer'} will always try
                    to fix the issue
                    <StrongText> within the first hour of the visit</StrongText>
                  </>
                )}
              </p>
            </li>
            <li>
              <TickCircle />
              <p>
                {isOVOPartner ? (
                  <>
                    If you need replacement parts, we&apos;ll
                    <StrongText> quote you real time</StrongText>
                  </>
                ) : (
                  <>
                    If you need replacement parts, the engineer will show you a
                    <StrongText> fixed-price list</StrongText>
                  </>
                )}
              </p>
            </li>
          </El.List>
        </div>
      </El.Card>
    </El.Container>
  );
};
