import styled from '@emotion/styled';

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.app.background.white};
  grid-column: 1/-1;
  box-shadow: 0 0 0 100vmax ${({ theme }) => theme.app.background.white};
  clip-path: inset(0 -100vmax);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MicroComboSummary = styled(Container)`
  min-height: 88px;
`;

const MicroComboWidget = styled.div`
  width: 100%;
`;

const MicroComboLink = styled.a`
  width: 100%;
  text-align: center;
  display: inline-block;
`;

const Testimonials = styled(Container)`
  max-width: calc(vw - 40px);
  min-height: 240px;
  padding: ${({ theme }) => theme.app.padding.largePadding};
  margin: 0 auto;
`;

const TrustPilotCite = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const TrustBoxStyles = {
  Container,
  MicroComboSummary,
  MicroComboWidget,
  MicroComboLink,
  Testimonials,
  TrustPilotCite,
};

export { TrustBoxStyles };
