import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1048px;
  text-align: center;
  color: ${({ theme }) => theme.app.landingPage.generalText.major};
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (${mediaQuery('tablet')}) {
    flex-direction: row;
    justify-content: center;
    gap: ${({ theme }) => theme.app.spacing[16]};
  }
`;

const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.app.background.white};
  border-radius: ${({ theme }) => theme.app.borderRadius['extraLargeCorner']};
  margin-bottom: ${({ theme }) => theme.app.spacing[16]};
  padding: ${({ theme }) => theme.app.spacing[40]}
    ${({ theme }) => theme.app.spacing[24]};

  @media (${mediaQuery('tablet')}) {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    width: 50%;
  }
`;

const ContactTitle = styled.h4`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight['light']};
  margin-top: ${({ theme }) => theme.app.margin.largeMargin};
  margin-bottom: ${({ theme }) => theme.app.margin.largeMargin};

  @media (${mediaQuery('tablet')}) {
    margin-top: ${({ theme }) => theme.app.margin.extraExtraLargeMargin};
  }
`;

const ContactLink = styled.a`
  display: block;
  color: ${({ theme }) => theme.app.messages.action};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight['semiBold']};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  margin-bottom: ${({ theme }) => theme.app.margin.mediumMargin};

  @media (${mediaQuery('desktop')}) {
    margin-bottom: ${({ theme }) => theme.app.margin.mediumLargeMargin};
    margin-top: ${({ theme }) => theme.app.spacing[12]};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  }
`;

const OpeningTimes = styled.p`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  margin-bottom: 0;
`;

const NewSupportStyles = {
  Container,
  ContactContainer,
  ContactBox,
  ContactLink,
  ContactTitle,
  OpeningTimes,
};

export { NewSupportStyles };
