import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

import { ColorScheme } from './MarketingSection';

const MarketingSectionContainer = styled.section<{
  $colorScheme: ColorScheme;
  $paddingTop: number;
  $paddingBottom: number;
  $responsivePadding: boolean;
}>`
  grid-column: 1/-1;
  background-color: ${({ theme, $colorScheme }) =>
    theme.app.landingPage[$colorScheme].background};
  padding: ${({ $paddingTop }) => $paddingTop}px
    ${({ theme }) => theme.app.margin.largeMargin}
    ${({ $paddingBottom }) => $paddingBottom}px;
  margin: 0 -16px;
  display: flex;
  flex-direction: column;

  ${({ $responsivePadding, theme }) =>
    $responsivePadding &&
    `
    padding: ${theme.app.padding.extraLargePadding}
    ${theme.app.padding.mediumPadding};
    @media (${mediaQuery('tablet')}) {
          padding: ${theme.app.padding.massivePadding}
    ${theme.app.padding.largePadding};
    }
  `}
`;

const MarketingSectionTitle = styled.h2<{
  $colorScheme: ColorScheme;
  $actionTitle?: boolean;
  $isKantan?: boolean;
}>`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[32]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
  color: ${({ theme, $colorScheme }) =>
    theme.app.landingPage[$colorScheme].title};
  line-height: 1.2;
  margin-bottom: ${({ theme }) => theme.app.margin.mediumMargin};
  text-align: center;
  letter-spacing: -0.04em;
  position: relative;

  ${({ $actionTitle, $isKantan, theme }) =>
    $actionTitle &&
    `
    font-size:  ${theme.app.fontSettings.fontSize[24]};
    color: ${
      $isKantan ? theme.app.messages.actionDark : theme.app.messages.action
    };
    max-width: 700px;
    margin: 0 auto ${theme.app.margin.largeMargin};
    line-height: 1.3;

    @media (${mediaQuery('tablet')}) {
      margin-bottom: ${theme.app.margin.extraExtraLargeMargin};
      font-size:  ${theme.app.fontSettings.fontSize[32]};
    }
  `}
`;

const MarketingSectionSubTitle = styled.h3<{
  $colorScheme: ColorScheme;
  $responsivePadding?: boolean;
}>`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  font-weight: ${({ theme, $colorScheme }) =>
    $colorScheme === 'light'
      ? theme.app.fontSettings.fontWeight.semiBold
      : theme.app.fontSettings.fontWeight.light};
  color: ${({ theme, $colorScheme, $responsivePadding }) =>
    $responsivePadding
      ? theme.app.landingPage.generalText.minor
      : theme.app.landingPage[$colorScheme].text};
  line-height: 1.63;
  text-align: center;
  letter-spacing: -0.04em;
  position: relative;
  max-width: 850px;
  margin: 0 auto;

  @media (min-width: 680px) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Content = styled.div<{
  $insetBackground: boolean;
  $extendBackground?: boolean;
  $reverse: boolean;
}>`
  ${({ $insetBackground, $reverse }) =>
    $insetBackground
      ? `
  position: relative;
  width: 100%;
  z-index: 1;
  max-width: 1280px;
  margin: 0 auto;
  padding: 36px;
  display: ${$reverse && 'flex'};
  flex-direction: ${$reverse ? 'column-reverse' : 'column'};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    z-index: 0;
    border-radius: 20px;
  }
  `
      : ``};

  ${({ theme, $extendBackground }) =>
    $extendBackground &&
    `
      box-shadow: 0 0 0 100vmax ${theme.app.background.white};
      clip-path: inset(0 -100vmax);
  `};
`;

const MarketingSectionStyles = {
  Container: MarketingSectionContainer,
  Title: MarketingSectionTitle,
  SubTitle: MarketingSectionSubTitle,
  Center,
  Content,
};

export { MarketingSectionStyles };
