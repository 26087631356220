import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (${mediaQuery('tablet')}) {
    flex-direction: row;
  }
`;

const Stat = styled.div`
  padding: ${({ theme }) => theme.app.padding.mediumLargePadding};
`;

const Percentage = styled.h3`
  color: ${({ theme }) => theme.app.messages.action};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[32]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
  line-height: 1.3;

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[60]};
  }
`;
const Description = styled.div``;

const Info = styled.div`
  width: 100%;
  text-align: center;
  margin-top: ${({ theme }) => theme.app.margin.massiveMargin};
`;

const StatsStyles = {
  Container,
  Stat,
  Percentage,
  Description,
  Info,
};

export { StatsStyles };
