import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (${mediaQuery('tablet')}) {
    margin-bottom: ${({ theme }) => theme.app.padding.extraLargePadding};
  }
`;

const Card = styled.div`
  padding: ${({ theme }) => theme.app.padding.mediumPadding};
  border-radius: ${({ theme }) => theme.app.borderRadius.extraLargeCorner};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.app.margin.largeMargin};
  align-items: center;

  @media (${mediaQuery('tablet')}) {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    flex-direction: row;
  }
`;

const Title = styled.h3<{ $isKantan?: boolean }>`
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  color: ${({ theme, $isKantan }) =>
    $isKantan ? theme.app.messages.actionDark : theme.app.messages.action};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};

  @media (${mediaQuery('desktop')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[36]};
  }
`;

const Price = styled.div<{ $isKantan?: boolean }>`
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  line-height: 1.3;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[42]};
  color: ${({ theme, $isKantan }) =>
    $isKantan ? theme.app.messages.actionDark : theme.app.messages.black};

  span {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  }

  @media (${mediaQuery('desktop')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[80]};

    span {
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[42]};
    }
  }
`;
const Vat = styled.p`
  margin-bottom: ${({ theme }) => theme.app.margin.largeMargin};
`;

const List = styled.ul`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  line-height: 1.3;

  li {
    margin-bottom: ${({ theme }) => theme.app.margin.largeMargin};
    display: flex;
    gap: ${({ theme }) => theme.app.margin.mediumLargeMargin};
  }

  @media (${mediaQuery('desktop')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
  }
`;

const TickCircleContainer = styled.div`
  min-width: 20px;
  margin-top: 2px;
`;

const PlumbingImageWrapper = styled.div`
  img {
    object-fit: cover;
  }
`;

const RepairCardStyles = {
  Container,
  Card,
  Title,
  Price,
  Vat,
  List,
  TickCircleContainer,
  PlumbingImageWrapper,
};

export { RepairCardStyles };
