import { useLandingPage } from 'hooks/useLandingPage';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import option3 from 'public/assets/images/Bathroom.webp';
import option2 from 'public/assets/images/BoilerChatWide.webp';
import option1 from 'public/assets/images/Flying.webp';
import React from 'react';

import { StrongText } from 'components/StrongText';
import { formatPrice, getDiscountAndFullPrice } from 'utils/formatPrice';
import { JOB_TYPES, SERVICE_TYPES_ROUTES } from 'utils/jobTypes';
import { getPriceFromJobType } from 'utils/tracking';
import { generateNextRoute } from 'utils/urls';
import { useManagedRates } from 'utils/useManagedRates';

import { ServiceOptionsStyles as El } from './ServiceOptions.styles';

export const ServiceOptions = () => {
  const managedRates = useManagedRates();
  const router = useRouter();
  const { isOVOPartner } = useLandingPage();

  const boilerServicePrice = getPriceFromJobType(
    JOB_TYPES.BOILER_SERVICE,
    managedRates,
  );
  const gasSafetyCertificatePrice = getPriceFromJobType(
    JOB_TYPES.GAS_SAFETY_CERTIFICATE,
    managedRates,
  );
  const boilerServiceComboPrice = getPriceFromJobType(
    JOB_TYPES.BOILER_SERVICE_COMBO,
    managedRates,
  );

  const {
    fullComboPriceRounded,
    discountAmountRounded,
    isPromoComboRate,
    promoComboDiscount,
  } = getDiscountAndFullPrice(managedRates);

  const serviceList = isOVOPartner ? serviceListsOVO : serviceLists;

  return (
    <El.Container>
      <El.OptionContainer>
        <Link
          passHref
          legacyBehavior
          href={{
            pathname: generateNextRoute({
              router,
              serviceType: SERVICE_TYPES_ROUTES.BOILER_SERVICE,
              nextRoute: '',
            }),
            query: {
              ...router.query,
              service_type: SERVICE_TYPES_ROUTES.BOILER_SERVICE,
            },
          }}
          replace
        >
          <El.Option>
            <El.ImageWrap>
              <Image
                src={option1}
                alt="Boiler Service"
                width={448}
                height={292}
                placeholder="blur"
                layout="responsive"
              />
            </El.ImageWrap>
            <El.OptionBody>
              <div>
                <El.OptionTitleWrapper>
                  <El.OptionTitle>Boiler Service</El.OptionTitle>
                  <El.OptionPrice>
                    £{formatPrice(boilerServicePrice.amount)}
                    {boilerServicePrice.isPromoRate && (
                      <El.DiscountOptionPrice>
                        £
                        {formatPrice(
                          boilerServicePrice.prePromoAmount ||
                            boilerServicePrice.amount,
                        )}
                      </El.DiscountOptionPrice>
                    )}
                    <El.VAT>
                      {boilerServicePrice.isAmountWithVat
                        ? 'inc. VAT'
                        : 'exc. VAT'}
                    </El.VAT>
                  </El.OptionPrice>
                </El.OptionTitleWrapper>
                <El.OptionInfo>{serviceList.boilerDescription}</El.OptionInfo>
              </div>
              <El.CoveredContainer>
                <El.CoveredTitle>What’s covered</El.CoveredTitle>
                <El.CoveredList>
                  {serviceList['boiler'].map((option, index) => {
                    return <li key={index}>{option}</li>;
                  })}
                </El.CoveredList>
              </El.CoveredContainer>
            </El.OptionBody>
          </El.Option>
        </Link>
        <Link
          passHref
          legacyBehavior
          href={{
            pathname: generateNextRoute({
              router,
              serviceType: SERVICE_TYPES_ROUTES.GAS_SAFETY_CERTIFICATE,
              nextRoute: '',
            }),
            query: {
              ...router.query,
              service_type: SERVICE_TYPES_ROUTES.GAS_SAFETY_CERTIFICATE,
            },
          }}
          replace
        >
          <El.Option>
            <El.ImageWrap>
              <Image
                src={option2}
                alt="Gas Safety Certificate"
                width={448}
                height={292}
                placeholder="blur"
                layout="responsive"
              />
            </El.ImageWrap>
            <El.OptionBody>
              <div>
                <El.OptionTitleWrapper>
                  <El.OptionTitle>Gas Safety Check</El.OptionTitle>
                  <El.OptionPrice>
                    £{formatPrice(gasSafetyCertificatePrice.amount)}
                    {gasSafetyCertificatePrice.isPromoRate && (
                      <El.DiscountOptionPrice>
                        £
                        {formatPrice(
                          gasSafetyCertificatePrice.prePromoAmount ||
                            gasSafetyCertificatePrice.amount,
                        )}
                      </El.DiscountOptionPrice>
                    )}
                    <El.VAT>
                      {gasSafetyCertificatePrice.isAmountWithVat
                        ? 'inc. VAT'
                        : 'exc. VAT'}
                    </El.VAT>
                  </El.OptionPrice>
                </El.OptionTitleWrapper>
                <El.OptionInfo>{serviceList.gasDescription}</El.OptionInfo>
              </div>
              <El.CoveredContainer>
                <El.CoveredTitle>What’s covered</El.CoveredTitle>
                <El.CoveredList>
                  {serviceList['gas'].map((option, index) => {
                    return <li key={index}>{option}</li>;
                  })}
                </El.CoveredList>
              </El.CoveredContainer>
            </El.OptionBody>
          </El.Option>
        </Link>
        <Link
          passHref
          legacyBehavior
          href={{
            pathname: generateNextRoute({
              router,
              serviceType: SERVICE_TYPES_ROUTES.COMBO_SAVER,
              nextRoute: '',
            }),
            query: {
              ...router.query,
              service_type: SERVICE_TYPES_ROUTES.COMBO_SAVER,
            },
          }}
          replace
        >
          <El.Option>
            <El.OptionImage>
              <El.ImageWrap>
                <Image
                  src={option3}
                  alt="Boiler Service &amp; Gas Safety Certificate"
                  width={448}
                  height={292}
                  placeholder="blur"
                  layout="responsive"
                />
              </El.ImageWrap>
              <El.OptionBanner>
                Combo Saver{' '}
                <StrongText>
                  Save £
                  {isPromoComboRate
                    ? promoComboDiscount
                    : discountAmountRounded}
                  !
                </StrongText>
              </El.OptionBanner>
            </El.OptionImage>
            <El.OptionBody>
              <div>
                <El.OptionTitleWrapper>
                  <El.OptionTitle>
                    Boiler Service &amp; Gas Safety Check
                  </El.OptionTitle>
                  <El.OptionPrice>
                    £{formatPrice(boilerServiceComboPrice.amount)}
                    <El.DiscountOptionPrice>
                      £
                      {boilerServiceComboPrice.isPromoRate
                        ? boilerServiceComboPrice.prePromoAmount
                        : fullComboPriceRounded}
                    </El.DiscountOptionPrice>
                    <El.VAT>
                      {boilerServiceComboPrice.isAmountWithVat
                        ? 'inc. VAT'
                        : 'exc. VAT'}
                    </El.VAT>
                  </El.OptionPrice>
                </El.OptionTitleWrapper>
                <El.OptionInfo>
                  {isOVOPartner
                    ? serviceList.comboDescription
                    : `Get ultimate peace of mind in a single visit and save £${
                        isPromoComboRate
                          ? promoComboDiscount
                          : discountAmountRounded
                      }!`}
                </El.OptionInfo>
              </div>
              <El.CoveredContainer>
                <El.CoveredTitle>What’s covered</El.CoveredTitle>
                <El.CoveredList>
                  {serviceList['combo'].map((option, index) => {
                    return <li key={index}>{option}</li>;
                  })}
                </El.CoveredList>
              </El.CoveredContainer>
            </El.OptionBody>
          </El.Option>
        </Link>
      </El.OptionContainer>
    </El.Container>
  );
};

const serviceListsOVO = {
  boilerDescription:
    'The service to help make sure your boiler is running smoothly and safely',
  gasDescription:
    'We’ll inspect the gas appliances and other gas fittings in your home to make sure they’re safe to use',
  comboDescription:
    'Combine both services in the same visit for a discounted price',
  boiler: [
    <React.Fragment key="boiler-1">
      A Gas Safe registered engineer will come to your home to radiators and hot
      water cylinder
    </React.Fragment>,
    <React.Fragment key="boiler-2">
      Efficiency and pressure checks for the boiler, boiler flues,and
      ventilation
    </React.Fragment>,
    <React.Fragment key="boiler-3">
      Cleaning of the boilers condensation traps
    </React.Fragment>,
    <React.Fragment key="boiler-4">
      Email confirmation of your boiler service
    </React.Fragment>,
  ],
  gas: [
    <React.Fragment key="gas-1">
      A Gas Safe registered engineer will come to your home to check the safety
      of your gas appliances and other gas fittings
    </React.Fragment>,
    <React.Fragment key="gas-2">
      Checks on the supply of combustion air, operating pressures, and heat
      input to check for safe operation
    </React.Fragment>,
    <React.Fragment key="gas-3">
      If the engineer spots anything that’s faulty or not safe, they’ll
      disconnect your gas supply before they leave
    </React.Fragment>,
    <React.Fragment key="gas-4">
      A certificate of completion and confirmation that your property is gas
      safe
    </React.Fragment>,
  ],
  combo: [
    <React.Fragment key="combo-1">
      A full boiler service and gas safety check in one visit
    </React.Fragment>,
    <React.Fragment key="combo-2">
      Both an email confirmation of your boiler service and a certificate of
      completion and confirmation that your property is gas safe
    </React.Fragment>,
  ],
};

const serviceLists = {
  boilerDescription:
    'The service you need to make sure your boiler is running smoothly and safely',
  gasDescription:
    'The service you need if you’re a landlord or if you want full peace of mind',
  comboDescription: 'Get ultimate peace of mind in a single visit and save',
  boiler: [
    <React.Fragment key="boiler-1">
      Inspection of internal parts for signs of defect or leakage.
    </React.Fragment>,
    <React.Fragment key="boiler-2">
      Flue inspection and flue gas analysis, pressure check and condensate
      clearance.
    </React.Fragment>,
    <React.Fragment key="boiler-3">
      Checking and cleaning magnetic filter cartridges.
    </React.Fragment>,
    <React.Fragment key="boiler-4">
      Inspection of radiators as well as bleeding them if necessary.
    </React.Fragment>,
    <React.Fragment key="boiler-5">
      You’ll get an <StrongText>official Boiler Service Certificate</StrongText>
      , which you’ll need to comply with your boiler’s manufacturer warranty.
    </React.Fragment>,
  ],
  gas: [
    <React.Fragment key="gas-1">
      Professional inspection by an experienced Gas Safe Registered engineer.
    </React.Fragment>,
    <React.Fragment key="gas-2">
      Checking the installation is safe and suitable for the location.{' '}
    </React.Fragment>,
    <React.Fragment key="gas-3">
      Making sure the appliances and safety devices are operating correctly
    </React.Fragment>,
    <React.Fragment key="gas-4">
      Inspection of air supplies, flues and chimneys
    </React.Fragment>,
    <React.Fragment key="gas-5">
      You’ll get an <StrongText>official Gas Safety Certificate</StrongText>,
      which you’ll need as a landlord.
    </React.Fragment>,
  ],
  combo: [
    <React.Fragment key="combo-1">
      Professional inspection of both your boiler and other gas burning
      appliances by an experienced Gas Safe Registered engineer.
    </React.Fragment>,
    <React.Fragment key="combo-2">
      Full boiler service and gas safety check in one visit
    </React.Fragment>,
    <React.Fragment key="combo-3">
      Inspection of radiators as well as bleeding them if necessary.
    </React.Fragment>,
    <React.Fragment key="combo-4">
      You`ll get both an{' '}
      <StrongText>official Boiler Service Certificate</StrongText> and a{' '}
      <StrongText>Gas Safety Certificate</StrongText>.
    </React.Fragment>,
  ],
};
