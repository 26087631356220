import { useLandingPage } from 'hooks/useLandingPage';
import Image from 'next/legacy/image';

import { TrustPilotCite } from 'components/redesign/TrustBox/TrustBox';

import { IndividualReviewStyles as El } from './IndividualReview.styles';

const Star = () => {
  return (
    <El.StarContainer>
      <Image
        src={'/assets/svg/StarNewIcon.svg'}
        alt="Star"
        width={48}
        height={48}
        layout="responsive"
      />
    </El.StarContainer>
  );
};

export const Stars = () => {
  return (
    <El.Stars>
      <Star />
      <Star />
      <Star />
      <Star />
      <Star />
    </El.Stars>
  );
};

interface IndividualReviewProps {
  quote: React.ReactNode;
  cite: string;
  showTrustPilot?: boolean;
}

export const IndividualReview = ({
  quote,
  cite,
  showTrustPilot = false,
}: IndividualReviewProps) => {
  const { isKantanPartner } = useLandingPage();
  return (
    <El.Container>
      <Stars />
      <El.Quote>{quote}</El.Quote>
      <El.Cite>
        {cite}
        {showTrustPilot && isKantanPartner && <TrustPilotCite />}
      </El.Cite>
    </El.Container>
  );
};
