import Head from 'next/head';
import KantanLogo from 'public/assets/meta-cover-kantan.png';
import OvoLogo from 'public/assets/meta-cover-ovo.png';

import { DEFAULT_REPAIR_PRICING } from 'layouts/MarketingLayout/MarketingLayout';
import { formatPrice } from 'utils/formatPrice';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  price?: string;
  disableJsonLD?: boolean;
  hostUrl: string;
  path?: string;
}

export const SEO = ({
  title,
  description,
  image,
  price,
  hostUrl,
  path,
  disableJsonLD = false,
}: SEOProps) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta itemProp="url" content={`https://${hostUrl}${path ?? ''}`} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={`https://${hostUrl}${image}`} />
      <link rel="canonical" href={`https://${hostUrl}${path ?? ''}`} />

      {/*Facebook Meta Tags*/}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`https://${hostUrl}${path ?? ''}`} />
      <meta property="og:type" content="website" />
      <meta property="site_name" content="Kantan" />
      <meta property="og:image" content={`https://${hostUrl}${image}`} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:locale" content="en_GB" />

      {/*Twitter Meta Tags*/}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={title} />
      <meta name="twitter:site" content="@KantanTech" />
      <meta name="twitter:creator" content="@KantanTech" />
      <meta property="twitter:domain" content={hostUrl} />
      <meta
        property="twitter:url"
        content={`https://${hostUrl}${path ?? ''}`}
      />
      <meta name="twitter:image" content={`https://${hostUrl}${image}`} />

      {/*Google JSON-LD*/}
      {!disableJsonLD && (
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "${title}",
            "description": "${description}",
            "image": [
              "https://${hostUrl}${image}"
            ],
            "review": {
              "@type": "Review",
              "author": {
                "@type": "Person",
                "name": "Kantan"
              },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": "5",
                "bestRating": "5"
              }
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "reviewCount": "5"
            },
            "offers": {
              "@type": "Offer",
              "priceCurrency": "GBP",
              "price": "${price}",
              "url": "https://${hostUrl}",
              "itemCondition": "https://schema.org/NewCondition",
              "availability": "https://schema.org/InStock"
            }
          }
        `}
        </script>
      )}
    </Head>
  );
};

const partner = process.env.NEXT_PUBLIC_PARTNER;
const isKantanPartner = partner === 'KANTAN';

SEO.defaultProps = {
  title: `Book a Boiler Repair Online Now for £${formatPrice(
    DEFAULT_REPAIR_PRICING,
  )}`,
  description:
    'Only Pay When Your Repair is Completed. Certified Gas Engineers Available Immediately in Your Area.',
  image: isKantanPartner ? KantanLogo.src : OvoLogo.src,
  price: DEFAULT_REPAIR_PRICING,
};
