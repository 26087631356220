import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { mediaQuery } from 'theme/kantan';

import { useNullableProductContext } from 'pages/[productType]/[rateDomain]/[step]';

import { currentPartner, PARTNERS } from '../utils/partners';

const getExtraPaddingForPartner = (partner?: PARTNERS | string) => {
  switch (partner) {
    case PARTNERS.OVO:
      return `padding-bottom: 60px;`;
    default:
      return ``;
  }
};

const GridSystem = styled.main`
  display: grid;
  grid-template-columns: repeat(12, minmax(16px, 1fr));
  grid-auto-rows: min-content;
  gap: 0 8px;
  min-height: 100vh;
  background-color: ${({ theme }) =>
    theme.app.background.layoutContainerMobile};

  @media (${mediaQuery('tablet')}) {
    background-color: ${({ theme }) =>
      theme.app.background.layoutContainerDesktop};
    grid-template-columns: repeat(12, minmax(38px, 1fr));
    gap: 0 24px;
  }

  @media (${mediaQuery('desktop')}) {
    grid-template-columns: repeat(12, minmax(52px, 1fr));
    gap: 0 32px;
  }
`;

const LayoutContainer = styled(GridSystem)<{ $partner?: PARTNERS | string }>`
  position: relative;
  padding: ${({ theme }) => theme.app.padding.mediumPadding};
  ${({ $partner }) => getExtraPaddingForPartner($partner)};
`;

const RedesignLayout = ({ children }: PropsWithChildren<unknown>) => {
  const context = useNullableProductContext();

  return (
    <LayoutContainer $partner={currentPartner} role="main">
      {children}
    </LayoutContainer>
  );
};

export default RedesignLayout;
