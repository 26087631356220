import { useLandingPage } from 'hooks/useLandingPage';

import { Button } from 'components/Button';
import { SERVICE_TYPES_ROUTES } from 'utils/jobTypes';

import { StartBookingStyles as El } from './StartBooking.styles';

export const StartBooking = () => {
  const { service_type, isKantanPartner, isRepair, isPlumbing, isOVOPartner } =
    useLandingPage();

  const getTitle = () => {
    let serviceType;

    if (isOVOPartner) {
      if (isPlumbing) return 'Book your plumbing repair in minutes';
      if (isRepair) return 'Book your boiler repair in minutes';
    }

    switch (service_type) {
      case SERVICE_TYPES_ROUTES.COMBO_SAVER:
        serviceType = 'service + gas safety check';
        break;
      case SERVICE_TYPES_ROUTES.GAS_SAFETY_CERTIFICATE:
        serviceType = 'gas safety check';
        break;
      case SERVICE_TYPES_ROUTES.HEATING_REPAIR:
        serviceType = 'boiler repaired';
        break;
      case SERVICE_TYPES_ROUTES.PLUMBING_REPAIR:
        serviceType = 'plumbing repaired';
        break;
      case SERVICE_TYPES_ROUTES.BOILER_SERVICE:
      default:
        serviceType = 'boiler serviced';
        break;
    }

    return `Get your ${serviceType} the easy way`;
  };

  return (
    <El.Container>
      <El.CTA>
        <El.Title>{getTitle()}</El.Title>
        <Button
          size="large"
          onClick={() =>
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })
          }
        >
          Start your booking
        </Button>
        {!isKantanPartner && !isPlumbing && (
          <El.SubText>Trusted by thousands of homeowners¹</El.SubText>
        )}
      </El.CTA>
    </El.Container>
  );
};
