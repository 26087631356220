import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

const Container = styled.div`
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  color: ${({ theme }) => theme.app.landingPage.generalText.major};
`;

const OptionContainer = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  gap: ${({ theme }) => theme.app.margin.largeMargin};

  > div:first-of-type {
    margin-bottom: ${({ theme }) => theme.app.margin.largeMargin};
  }
  > div:last-of-type {
    margin-top: ${({ theme }) => theme.app.margin.largeMargin};
  }

  @media (${mediaQuery('desktop')}) {
    grid-auto-flow: column;

    > div:first-of-type {
      margin-right: ${({ theme }) => theme.app.margin.largeMargin};
      margin-bottom: 0;
    }
    > div:last-of-type {
      margin-left: ${({ theme }) => theme.app.margin.largeMargin};
      margin-top: 0;
    }
  }
`;

const Option = styled.div`
  flex: 1;
  transition: 0.2s ease-out;
  border-radius: ${({ theme }) => theme.app.borderRadius.extraLargeCorner};
  padding: ${({ theme }) => theme.app.padding.mediumPadding};
  background: white;
  cursor: pointer;
  height: 100%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);

  @media (${mediaQuery('desktop')}) {
    display: flex;
    flex-direction: column;
  }
`;

const OptionImage = styled.div`
  position: relative;
`;

const OptionBanner = styled.div`
  background-color: ${({ theme }) => theme.app.messages.action};
  color: white;
  padding: ${({ theme }) => theme.app.padding.smallPadding};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
  line-height: 1.3;
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: ${({ theme }) => theme.app.borderRadius.extraLargeCorner};
  text-align: center;
  strong {
    display: block;
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
    margin-top: ${({ theme }) => theme.app.margin.smallMargin};
  }
  svg {
    margin-right: ${({ theme }) => theme.app.padding.smallPadding};
  }
`;

const OptionBody = styled.div`
  width: 100%;
  padding-top: ${({ theme }) => theme.app.padding.mediumPadding};
  background-color: ${({ theme }) => theme.app.background.white};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const OptionTitle = styled.div`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  color: ${({ theme }) => theme.app.messages.action};

  @media (${mediaQuery('desktop')}) {
    height: 50px;
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  }
`;

const OptionTitleWrapper = styled.div`
  @media (${mediaQuery('desktop')}) {
    min-height: 100px;
  }
`;

const OptionPrice = styled.div`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[42]};
  line-height: 1.3;
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  margin: ${({ theme }) => theme.app.margin.smallMargin} 0
    ${({ theme }) => theme.app.margin.largeMargin};

  @media (${mediaQuery('desktop')}) {
    margin-bottom: 0;
  }
  > strong {
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  }

  @media (${mediaQuery('desktop')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[54]};
  }
`;

const VAT = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};
  margin-bottom: ${({ theme }) => theme.app.margin.mediumMargin};
`;

const OptionInfo = styled.div`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
  line-height: 26px;
  letter-spacing: -0.02em;
  margin-bottom: ${({ theme }) => theme.app.margin.largeMargin};

  @media (${mediaQuery('desktop')}) {
    height: 100px;
    margin-bottom: ${({ theme }) => theme.app.margin.mediumMargin};
  }
`;

const DiscountOptionPrice = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
  margin-left: ${({ theme }) => theme.app.margin.mediumMargin};
  text-decoration: line-through;
  color: ${({ theme }) => theme.app.colors.grey.darker};

  @media (${mediaQuery('desktop')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[36]};
  }
`;

const ImageWrap = styled.div`
  overflow: hidden;
  text-align: center;
  border-radius: ${({ theme }) => theme.app.borderRadius.extraLargeCorner};
`;

const CoveredTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.app.margin.mediumMargin};
`;

const CoveredContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
  border-radius: 0 0 ${({ theme }) => theme.app.borderRadius.extraLargeCorner}
    ${({ theme }) => theme.app.borderRadius.extraLargeCorner};

  ul {
    flex-grow: 1;
    height: 100%;
  }
`;

const CoveredList = styled.ul`
  padding: ${({ theme }) => theme.app.padding.mediumPadding};
  background-color: #f3f3f3;
  border-radius: ${({ theme }) => theme.app.borderRadius.extraLargeCorner};

  li {
    padding-left: ${({ theme }) => theme.app.padding.mediumPadding};
    margin-bottom: ${({ theme }) => theme.app.margin.largeMargin};
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 10px;
      width: 4px;
      height: 4px;
      background: black;
      border-radius: 100%;
    }
  }
`;

const ServiceOptionsStyles = {
  Container,
  OptionContainer,
  Option,
  OptionImage,
  OptionBanner,
  DiscountOptionPrice,
  OptionBody,
  OptionTitle,
  OptionTitleWrapper,
  OptionPrice,
  OptionInfo,
  VAT,
  ImageWrap,
  CoveredContainer,
  CoveredTitle,
  CoveredList,
};

export { ServiceOptionsStyles };
