import { useLandingPage } from 'hooks/useLandingPage';

import { StrongText } from 'components/StrongText';

import { StatsStyles as El } from './Stats.styles';

export const Stats = () => {
  const { isOVOPartner } = useLandingPage();
  return (
    <El.Container>
      <El.Stat>
        <El.Percentage>70%</El.Percentage>
        <El.Description>
          of problems <StrongText>fixed in 1hr</StrongText>
        </El.Description>
      </El.Stat>
      <El.Stat>
        <El.Percentage>89%</El.Percentage>
        <El.Description>
          of problems <StrongText>fixed in 2hrs</StrongText>
        </El.Description>
      </El.Stat>
      <El.Stat>
        <El.Percentage>73%</El.Percentage>
        <El.Description>
          of fixes require <StrongText>no new parts</StrongText>
        </El.Description>
      </El.Stat>

      {isOVOPartner && (
        <El.Info>
          Based on data from 200,00 Boiler Services in 2022. These figures are
          correct as of 28 March 2023.
        </El.Info>
      )}
    </El.Container>
  );
};
