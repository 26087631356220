import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { mediaQuery } from 'theme/kantan';

const NavBar = styled(m.nav)`
  z-index: 2;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.app.padding.mediumPadding};
`;

const PageName = styled.h2`
  color: ${({ theme }) => theme.app.text.white};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize['18']};
  line-height: 1.3;
  padding-left: ${({ theme }) => theme.app.padding.mediumPadding};
  margin-left: ${({ theme }) => theme.app.margin.mediumMargin};
  border-left: 1px solid ${({ theme }) => theme.app.text.white};

  @media (${mediaQuery('tablet')}) {
    display: block;
  }
`;

const NavBarStyles = {
  NavBar,
  Container,
  PageName,
};

export { NavBarStyles };
