import styled from '@emotion/styled';

const Header = styled.div`
  grid-column: 1 / -1;
  z-index: 5;
  margin: -16px -16px 0;
  background: ${({ theme }) => theme.app.background.white};
  overflow-x: hidden;
`;

const LandingPageStyles = {
  Header,
};

export { LandingPageStyles };
