import { useLandingPage } from 'hooks/useLandingPage';

import { Hero } from 'components/redesign/Hero/Hero';
import { MarketingSection } from 'components/redesign/MarketingSection';
import { Footer } from 'components/redesign/MarketingSection/content/Footer';
import { GridIcons } from 'components/redesign/MarketingSection/content/GridIcons';
import { Highlights } from 'components/redesign/MarketingSection/content/Highlights';
import { IndividualReview } from 'components/redesign/MarketingSection/content/IndividualReview';
import { NewSupport } from 'components/redesign/MarketingSection/content/NewSupports';
import { RepairBanner } from 'components/redesign/MarketingSection/content/RepairBanner';
import { RepairCard } from 'components/redesign/MarketingSection/content/RepairCard';
import { ServiceOptions } from 'components/redesign/MarketingSection/content/ServiceOptions';
import { SimpleSteps } from 'components/redesign/MarketingSection/content/SimpleSteps';
import { StartBooking } from 'components/redesign/MarketingSection/content/StartBooking';
import { Stats } from 'components/redesign/MarketingSection/content/Stats';
import { NavBar } from 'components/redesign/NavBar/NavBar';
import { StrongText } from 'components/StrongText';

import { LandingPageStyles as El } from './LandingPage.styles';
import { REVIEWS } from './Reviews';

export const LandingPage = () => {
  const {
    isKantanPartner,
    isOVOPartner,
    product_type,
    jobType,
    isPlumbing,
    isRepair,
    partner,
    getGridIconsTitle,
    partnerCopyShort,
    getSimpleStepsTitle,
    getHighlightSubtitle,
  } = useLandingPage();

  return (
    <>
      <El.Header>
        <NavBar />
        <Hero />
      </El.Header>
      <MarketingSection
        colorScheme={isKantanPartner ? 'light' : 'muted'}
        responsivePadding
      >
        <IndividualReview
          quote={REVIEWS.reviewOne.text(partnerCopyShort, jobType)}
          cite={REVIEWS.reviewOne.name(jobType)}
          showTrustPilot
        />
      </MarketingSection>
      <MarketingSection
        responsivePadding
        colorScheme="white"
        actionTitle
        title={
          isOVOPartner
            ? `Why have your ${
                isPlumbing ? 'plumbing' : 'boiler'
              } repaired with us?`
            : 'We do things differently'
        }
        subTitle={getHighlightSubtitle()}
      >
        <Highlights />
      </MarketingSection>
      <MarketingSection colorScheme={isKantanPartner ? 'light' : 'muted'}>
        <IndividualReview
          quote={REVIEWS.reviewTwo.text(jobType)}
          cite={REVIEWS.reviewTwo.name(jobType)}
        />
      </MarketingSection>
      {!isRepair && (
        <>
          <MarketingSection colorScheme="white" responsivePadding>
            <StartBooking />
          </MarketingSection>
          <MarketingSection
            colorScheme="veryLight"
            title={`Advantages of getting your ${getGridIconsTitle()} ${
              !isOVOPartner ? `from ${partnerCopyShort}` : ''
            }`}
            actionTitle
            responsivePadding
          >
            <GridIcons />
          </MarketingSection>
        </>
      )}
      <MarketingSection colorScheme="white" responsivePadding>
        <StartBooking />
      </MarketingSection>
      <MarketingSection
        colorScheme="veryLight"
        title={
          isOVOPartner ? (
            <>
              Put your mind at ease <br /> in 3 steps
            </>
          ) : (
            <>
              Get a {getSimpleStepsTitle()} in <br /> 3 simple steps
            </>
          )
        }
        responsivePadding
        actionTitle
      >
        <SimpleSteps />
      </MarketingSection>
      {!isPlumbing && (
        <>
          <MarketingSection colorScheme="white" responsivePadding>
            <StartBooking />
          </MarketingSection>
          <MarketingSection
            colorScheme={isKantanPartner ? 'light' : 'muted'}
            responsivePadding
          >
            <IndividualReview
              quote={REVIEWS.reviewThree.text()}
              cite={REVIEWS.reviewThree.name()}
              showTrustPilot
            />
          </MarketingSection>
        </>
      )}

      {isRepair && (
        <>
          <MarketingSection
            colorScheme="white"
            responsivePadding
            actionTitle
            title="How much will it cost?"
          >
            <RepairCard />
            {!isPlumbing && <Stats />}
          </MarketingSection>
          <MarketingSection
            colorScheme={isKantanPartner ? 'light' : 'muted'}
            responsivePadding
          >
            <IndividualReview
              quote={REVIEWS.reviewFour.text(jobType)}
              cite={REVIEWS.reviewFour.name(jobType)}
              showTrustPilot
            />
          </MarketingSection>
          <MarketingSection colorScheme="white" responsivePadding>
            <Highlights compressed={true} />
          </MarketingSection>
          <MarketingSection
            colorScheme={isKantanPartner ? 'light' : 'muted'}
            responsivePadding
          >
            <IndividualReview
              quote={REVIEWS.reviewFive.text(jobType)}
              cite={REVIEWS.reviewFive.name(jobType)}
            />
          </MarketingSection>
        </>
      )}

      {!isRepair && (
        <>
          <MarketingSection
            title="Our services"
            colorScheme="white"
            responsivePadding
            actionTitle
          >
            <ServiceOptions />
          </MarketingSection>
          <MarketingSection responsivePadding colorScheme="white">
            <StartBooking />
          </MarketingSection>
          <MarketingSection
            paddingTop={0}
            paddingBottom={80}
            colorScheme="white"
          >
            <RepairBanner />
          </MarketingSection>
          <MarketingSection
            colorScheme={isKantanPartner ? 'light' : 'muted'}
            responsivePadding
          >
            <IndividualReview
              quote={
                <p>
                  “<StrongText>Engineer was on time</StrongText>, rang me
                  beforehand as requested. Did a great job, and{' '}
                  <StrongText>pointed out a really major fault</StrongText> that
                  none of my other gas safety check people has ever picked up
                  on. Spot on.”
                </p>
              }
              cite="Hannah S."
            />
          </MarketingSection>
          <MarketingSection colorScheme="white" responsivePadding>
            <Highlights compressed={true} />
          </MarketingSection>
          <MarketingSection
            colorScheme={isKantanPartner ? 'light' : 'muted'}
            responsivePadding
          >
            <IndividualReview
              quote={
                <p>
                  “Excellent service, I was{' '}
                  <StrongText>
                    kept informed with confirmation and reminders
                  </StrongText>{' '}
                  along with my serviceman’s name and that he would telephone me
                  shortly before his arrival, which he did. David the serviceman
                  was <StrongText>prompt, polite and friendly</StrongText> and
                  definitely knew his craft . I would highly recommend this
                  service.”
                </p>
              }
              cite="Tanya W."
            />
          </MarketingSection>
        </>
      )}

      <MarketingSection colorScheme="white" responsivePadding>
        <StartBooking />
      </MarketingSection>
      <MarketingSection
        title="Need to contact us?"
        colorScheme="veryLight"
        id="support"
        responsivePadding
        actionTitle
      >
        <NewSupport partner={partner} />
      </MarketingSection>
      <MarketingSection colorScheme="white" paddingTop={50}>
        <Footer
          productType={
            Array.isArray(product_type) ? product_type[0] : product_type
          }
          isKantanPartner={isKantanPartner}
          partner={partner}
        />
      </MarketingSection>
    </>
  );
};
