import Link from 'next/link';

import { RepairBannerStyles as El } from './RepairBanner.styles';

export const RepairBanner = () => {
  return (
    <El.Container>
      <h3>Is something wrong with your boiler? </h3>
      <Link passHref href="/repair/heating" replace>
        Book a repair instead
      </Link>
    </El.Container>
  );
};
