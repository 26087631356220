import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import { useLandingPage } from 'hooks/useLandingPage';
import { usePartnerToken } from 'hooks/usePartnerToken';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import {
  useAddressStore,
  useCalloutsStore,
  useGenericStore,
  useServicesStore,
} from 'store';

import { LandingPage } from 'components/redesign/LandingPages/LandingPage/LandingPage';
import { SEO } from 'components/SEO';
import { DEFAULT_BRAND } from 'layouts/MarketingLayout/MarketingLayout';
import RedesignLayout from 'layouts/RedesignLayout';
import { trackPageView } from 'services/analytics';
import { dehydrateState } from 'utils/api';
import { getQueryClient } from 'utils/api/queryClient';
import { getPreloadedData } from 'utils/beyond/preloaded-data';
import { getServerCookies, ServerCookies } from 'utils/cookies';
import { grabGclid } from 'utils/tracking';
import { NextPageWithLayout } from 'utils/types';
import { isBeyond } from 'utils/urls';

import { CustomerSource } from '../../store/types';

const ServicesLanding: NextPageWithLayout = ({
  hostUrl,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const setAddressState = useAddressStore((state) => state.setState);
  const setCalloutsState = useCalloutsStore((state) => state.setState);
  const setServicesState = useServicesStore((state) => state.setState);
  const setGenericState = useGenericStore((state) => state.setState);
  const router = useRouter();

  const {
    isRepair,
    jobType,
    service_type,
    product_type,
    currentPrice,
    seoTitle,
    seoDescription,
  } = useLandingPage();

  const setResidentDetails = isRepair ? setCalloutsState : setServicesState;
  void usePartnerToken();

  // if running in OVO Beyond WebView, update store with the injected data
  useEffect(() => {
    if (!isBeyond(router.asPath)) return;

    const beyondCustomerData = getPreloadedData();

    if (!beyondCustomerData) return;

    const { address, contact, userCanEditDetails } = beyondCustomerData;
    setAddressState(address);
    setResidentDetails({ ...contact, customerSource: CustomerSource.BEYOND });
    setGenericState({ userCanEditDetails });
  }, [router.asPath, setAddressState, setGenericState, setResidentDetails]);

  useEffect(() => {
    if (jobType !== undefined) {
      trackPageView('Portal-Service page viewed', {
        brand: DEFAULT_BRAND,
        job_type: jobType,
        gclid: grabGclid(router),
      });
    }
  }, [jobType, router, service_type]);

  useEffect(() => {
    if (!!jobType && isRepair) {
      setCalloutsState({ jobType });
    }
  }, [isRepair, jobType, setCalloutsState]);

  return (
    <>
      {/*TODO(adrian.pop): Re-enable Google JSON-LD once we have added all products and reviews*/}
      <SEO
        hostUrl={hostUrl}
        path={`/${product_type}/${service_type}`}
        title={seoTitle(service_type)}
        description={seoDescription(service_type, currentPrice.split('.')[0])}
        disableJsonLD={!isRepair}
        price={currentPrice}
      />

      <LandingPage />
    </>
  );
};

ServicesLanding.getLayout = function getLayout(page) {
  return <RedesignLayout>{page}</RedesignLayout>;
};

// React-query complains if we pass in relative urls to our API Routes
// therefore we need to pass in the hostUrl and call that instead
async function getManagedRates({
  hostUrl,
  url,
  cookies,
}: {
  hostUrl?: string;
  url?: string;
  cookies: ServerCookies;
}) {
  if (!hostUrl || !url) return;
  const protocol = 'http';

  try {
    const res = await axios.get(
      `${protocol}://${hostUrl}/api/services/get-managed-rates`,
      {
        params: { cookies: JSON.stringify(cookies) },
        headers: { url },
      },
    );
    return res.data;
  } catch (e) {
    captureException(e);
  }
}

export const getServerSideProps: GetServerSideProps = async ({
  req,
  res,
  query,
}) => {
  const hostUrl = req.headers.host;
  let queryUrl = req.url;
  const productType = query.product_type as string | undefined;

  const isBeyond = req.url?.includes(`beyond/${productType}`);
  const queryClient = getQueryClient(req);

  if (
    req.url?.includes('_next') &&
    productType &&
    req.headers.referer?.includes(productType)
  ) {
    queryUrl = isBeyond ? `/beyond/${productType}` : `/${productType}`;
  }

  const protocol = 'http';

  await queryClient.prefetchQuery(
    ['managed-rates'],
    () =>
      getManagedRates({
        hostUrl,
        url: `${protocol}://${hostUrl}${queryUrl}`,
        cookies: getServerCookies(req, res),
      }),
    {
      staleTime: 5 * 60 * 1000,
    },
  );

  const { configCatClient } = await import('flagClient');

  return {
    props: {
      hostUrl,
      dehydratedState: dehydrateState(queryClient),
    },
  };
};

export default ServicesLanding;
