import { useLandingPage } from 'hooks/useLandingPage';
import Image from 'next/image';

import { HighlightsStyles as El } from './Highlights.styles';

interface HighlightsProps {
  compressed?: boolean;
}

export const Highlights = ({ compressed = false }: HighlightsProps) => {
  const { partner, isOVOPartner, isRepair, isPlumbing } = useLandingPage();
  const lowercasePartner = partner?.toLowerCase();

  const options = isOVOPartner
    ? optionsOVO({ isRepair, isPlumbing })
    : optionsKantan;

  return (
    <El.Container>
      <El.Card $compressed={compressed}>
        <El.MainIconWrap>
          <Image
            src={`/assets/svg/${lowercasePartner}/LaptopIcon.svg`}
            alt="Fully online booking"
            width={40}
            height={40}
          />
        </El.MainIconWrap>
        <TitleAndDescription
          options={options.booking}
          compressed={compressed}
        />
      </El.Card>
      <El.Card $compressed={compressed}>
        <El.MainIconWrap>
          <Image
            src={`/assets/svg/${lowercasePartner}/PricingIcon.svg`}
            alt="No hidden cost"
            width={40}
            height={40}
          />
        </El.MainIconWrap>
        <TitleAndDescription options={options.cost} compressed={compressed} />
      </El.Card>
      <El.Card $compressed={compressed}>
        <El.MainIconWrap>
          <Image
            src={`/assets/svg/${lowercasePartner}/CalendarIcon.svg`}
            alt="Confirmed appointments"
            width={40}
            height={40}
          />
        </El.MainIconWrap>
        <TitleAndDescription
          options={options.appointment}
          compressed={compressed}
        />
      </El.Card>
    </El.Container>
  );
};

const Icon = ({ iconType }: { iconType: 'Tick' | 'Cross' }) => {
  return (
    <El.IconWrap>
      <Image
        src={`/assets/svg/${iconType}Icon.svg`}
        alt={`${iconType} Icon`}
        width={32}
        height={32}
      />
    </El.IconWrap>
  );
};
interface TitleAndDescriptionProps {
  options: { title: string; description: string };
  compressed?: boolean;
}

const TitleAndDescription = ({
  options,
  compressed = false,
}: TitleAndDescriptionProps) => {
  return (
    <>
      <El.Title $compressed={compressed}>
        {!compressed && <Icon iconType="Tick" />}
        {options['title']}
      </El.Title>
      {!compressed && (
        <El.Description $compressed={compressed}>
          <El.Text>{options.description}</El.Text>
        </El.Description>
      )}
    </>
  );
};

const optionsKantan = {
  booking: {
    title: 'Simple online booking',
    description:
      'No more endless phone calls just to find an available engineer.',
  },
  cost: {
    title: 'Transparent pricing',
    description:
      'No more eye-watering prices and sneaky last minute hidden charges.',
  },
  appointment: {
    title: 'Confirmed appointments',
    description:
      'No more waiting in all day for the engineer not to even turn up.',
  },
};

const optionsOVO = ({
  isRepair = false,
  isPlumbing = false,
}: {
  isRepair?: boolean;
  isPlumbing?: boolean;
}) => {
  const noHiddenCostDescription =
    !isRepair && !isPlumbing
      ? 'No sneaky last-minute hidden charges, our services are a fixed price.'
      : "No last minute charges. Our services are a fixed hourly price. If parts are required, you'll be quoted transparent costs that YOU approve.";

  return {
    booking: {
      title: 'Fully online booking',
      description: `No need to call or find your own local ${
        isPlumbing ? 'plumber' : 'engineer'
      }, we will assign one to you at your chosen time slot.`,
    },
    cost: {
      title: 'No hidden cost',
      description: noHiddenCostDescription,
    },
    appointment: {
      title: 'Confirmed appointments',
      description:
        'No fuss. You will receive a booking confirmation and will be contacted in the event of any updates.',
    },
  };
};
