import Image from 'next/image';
import { useEffect, useRef } from 'react';

import { TrustBoxStyles as TBS } from './TrustBox.styles';

declare global {
  interface Window {
    Trustpilot: {
      loadFromElement(element: HTMLElement | null, forceReload: boolean): void;
    };
  }
}

export const Horizontal = () => {
  const ref = useRef(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> or <body /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  if (!process.env.NEXT_PUBLIC_TRUSTPILOT_BUSINESS_UNIT_ID) return null;

  return (
    <TBS.MicroComboSummary>
      <TBS.MicroComboWidget
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-UK"
        data-template-id="5406e65db0d04a09e042d5fc"
        data-businessunit-id={
          process.env.NEXT_PUBLIC_TRUSTPILOT_BUSINESS_UNIT_ID
        }
        data-style-height="28px"
        data-style-width="100%"
        data-theme="light"
        data-stars="4,5"
      >
        <TBS.MicroComboLink
          href="https://www.trustpilot.com/review/kantan.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot Summary Loading...
        </TBS.MicroComboLink>
      </TBS.MicroComboWidget>
    </TBS.MicroComboSummary>
  );
};

export const MicroCombo = () => {
  const ref = useRef(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> or <body /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  if (!process.env.NEXT_PUBLIC_TRUSTPILOT_BUSINESS_UNIT_ID) return null;

  return (
    <TBS.MicroComboSummary>
      <TBS.MicroComboWidget
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-UK"
        data-template-id="5419b732fbfb950b10de65e5"
        data-businessunit-id={
          process.env.NEXT_PUBLIC_TRUSTPILOT_BUSINESS_UNIT_ID
        }
        data-style-height="28px"
        data-style-width="100%"
        data-theme="light"
        data-stars="4,5"
      >
        <TBS.MicroComboLink
          href="https://www.trustpilot.com/review/kantan.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot Summary Loading...
        </TBS.MicroComboLink>
      </TBS.MicroComboWidget>
    </TBS.MicroComboSummary>
  );
};

export const Testimonials = () => {
  const ref = useRef(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> or <body /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  if (!process.env.NEXT_PUBLIC_TRUSTPILOT_BUSINESS_UNIT_ID) return null;

  return (
    <TBS.Testimonials
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="53aa8912dec7e10d38f59f36"
      data-businessunit-id={process.env.NEXT_PUBLIC_TRUSTPILOT_BUSINESS_UNIT_ID}
      data-style-height="140px"
      data-style-width="100%"
      data-theme="light"
      data-tags="SelectedReview"
      data-review-languages="en"
    >
      <a
        href="https://www.trustpilot.com/review/kantan.co.uk"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot Testimonials Loading...
      </a>
    </TBS.Testimonials>
  );
};

export const TrustPilotCite = () => {
  return (
    <TBS.TrustPilotCite>
      <span>via</span>
      <Image
        src={'/assets/svg/TrustpilotLogo.svg'}
        width={126}
        height={40}
        alt="Trustpilot"
      />
    </TBS.TrustPilotCite>
  );
};

const TrustBox = {
  MicroCombo,
  Testimonials,
};

export { TrustBox };
