import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

import {
  StarContainer,
  Stars,
} from '../MarketingSection/content/IndividualReview.styles';

const Hero = styled.div`
  grid-column: 1 / -1;
  background: ${({ theme }) => theme.app.background.white};
  padding: 0 ${({ theme }) => theme.app.padding.smallPadding}
    ${({ theme }) => theme.app.padding.smallPadding};
  color: ${({ theme }) => theme.app.landingPage.generalText.minor};
  color: ${({ theme }) => theme.app.landingPage.generalText.minor};
`;

const Container = styled.header`
  max-width: 1200px;
  margin: 0 auto;

  @media (${mediaQuery('desktop')}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: row;
  }
`;

const TrackerContainer = styled.div`
  background: ${({ theme }) => theme.app.background.white};
  max-width: 1200px;
  margin: 0 auto;

  &:last-child {
    margin-bottom: ${({ theme }) => theme.app.margin.massiveMargin};
  }
`;

const Half = styled.div`
  position: relative;
  width: 100%;

  @media (${mediaQuery('desktop')}) {
    display: flex;
    flex-direction: column;
    min-height: 500px;
    align-items: flex-start;
  }
`;

const SubTitle = styled.p`
  color: ${({ theme }) => theme.app.messages.action};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  line-height: 1.3;
  margin-bottom: ${({ theme }) => theme.app.margin.mediumMargin};
  background-color: ${({ theme }) => theme.app.colors.lime.muted};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
  padding: ${({ theme }) => theme.app.padding.smallPadding};
  display: inline-block;
`;

const Title = styled.h1<{ $isKantan?: boolean }>`
  color: ${({ theme, $isKantan }) =>
    $isKantan ? theme.app.messages.actionDark : theme.app.messages.action};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[32]};
  margin-bottom: ${({ theme }) => theme.app.margin.extraLargeMargin};
  line-height: 1.3;
  max-width: 530px;

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[60]};
    margin-bottom: ${({ theme }) => theme.app.margin.mediumMargin};

    span {
      display: block;
    }
  }
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  line-height: 1.3;
  margin-bottom: ${({ theme }) => theme.app.margin.mediumLargeMargin};
  max-width: 600px;

  strong {
    color: ${({ theme }) => theme.app.messages.action};
  }

  @media (${mediaQuery('desktop')}) {
    margin-bottom: ${({ theme }) => theme.app.margin.extraLargeMargin};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
  }
`;

const List = styled.ul`
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  margin-bottom: ${({ theme }) => theme.app.margin.largeMargin};

  span {
    display: inline-block;
    margin-left: ${({ theme }) => theme.app.margin.mediumMargin};
  }

  li {
    margin-bottom: ${({ theme }) => theme.app.margin.smallMargin};
    display: flex;
    line-height: 1.3;
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};

    @media (${mediaQuery('desktop')}) {
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    }
  }
`;

const IconWrap = styled.div`
  margin-right: ${({ theme }) => theme.app.margin.mediumMargin};
  width: 13px;
  margin-top: -1px;
  @media (${mediaQuery('tablet')}) {
    width: 20px;
    margin-top: -1px;
  }
  img {
    width: 100%;
  }
`;

const ImageWrap = styled.div<{ $isKantan?: boolean; $isPlumbing?: boolean }>`
  width: 100%;
  height: 200px;
  overflow: hidden;

  img {
    object-position: top;
    object-fit: cover;
  }

  @media (${mediaQuery('tablet')}) {
    height: 250px;
  }

  ${({ $isPlumbing }) =>
    $isPlumbing &&
    `
    @media (${mediaQuery('tablet')}) {
      height: 350px;
      position: relative;
    }
  `}

  ${({ $isKantan }) =>
    $isKantan
      ? `
      @media (${mediaQuery('desktop')}) {
        position: absolute;
        top: -80px;
        right: -50px;
        width: 650px;
        height: 576px;
      }
  `
      : `
  @media (${mediaQuery('desktop')}) {
    transform: rotate(-12deg);
    position: absolute;
    top: -180px;
    left: 0;
    width: 900px;
    height: 576px;
    
      img {
        transform: rotate(12deg) translate(0, 200px) scale(1.5);
      }
    }
`}
`;

const PlumbingImageWrap = styled.div`
  @media (${mediaQuery('tablet')}) {
    height: 350px;
    position: absolute;
    width: 100%;
  }

  @media (${mediaQuery('desktop')}) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -100px;

    img {
      object-fit: contain;
    }
  }
`;

const Review = styled.blockquote`
  display: none;
  background: ${({ theme }) => theme.app.colors.translucent.white};
  z-index: 2;
  position: relative;
  text-align: center;
  max-width: 400px;
  padding: ${({ theme }) => theme.app.padding.largePadding}
    ${({ theme }) => theme.app.padding.mediumLargePadding};
  border-radius: ${({ theme }) => theme.app.borderRadius.extraLargeCorner};
  margin-bottom: 64px;

  ${Stars} {
    margin-bottom: ${({ theme }) => theme.app.margin.smallMargin};
  }

  ${StarContainer} {
    width: 28px;
    margin: 0 ${({ theme }) => theme.app.margin.smallMargin};
  }

  p {
    margin-bottom: ${({ theme }) => theme.app.margin.mediumMargin};
    font-style: italic;
  }

  cite {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};
    font-style: normal;
  }

  @media (${mediaQuery('desktop')}) {
    display: inline-block;
    margin: auto 0 50px 50px;
  }
`;

const Cost = styled.div<{ $isKantan?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  width: ${({ $isKantan }) => ($isKantan ? '105px' : '99px')};
  height: ${({ $isKantan }) => ($isKantan ? '73px' : '89px')};
  position: absolute;
  top: -10px;
  right: 20px;
  z-index: 1;
  color: ${({ theme }) => theme.app.messages.white};

  &::before {
    content: '';
    width: 105px;
    height: 105px;
    position: absolute;
    top: ${({ $isKantan }) => ($isKantan ? '-15px' : '-6px')};
    right: 0px;
    background: ${({ theme }) => theme.app.colors.lime.ovoLogo};
    z-index: -1;
    transform: rotate(-15deg);
  }

  ${({ $isKantan, theme }) =>
    $isKantan &&
    `
      &::before {
      background: ${theme.app.colors.green.base};
      border-radius: 50%;
      }
  `}

  @media (${mediaQuery('desktop')}) {
    top: 44px;
    left: ${({ $isKantan }) => ($isKantan ? '-46px' : '44px')};
    width: ${({ $isKantan }) => ($isKantan ? '149px' : '157px')};
    height: ${({ $isKantan }) => ($isKantan ? '149px' : '125px')};

    &::before {
      width: 184px;
      height: 184px;
      top: ${({ $isKantan }) => ($isKantan ? '-14px' : '-23px')};
      left: ${({ $isKantan }) => ($isKantan ? '-18px' : '-17px')};
    }
  }
`;

const Hour = styled.span`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
`;

const PricePrefix = styled.span`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  line-height: 1.2;

  @media (${mediaQuery('desktop')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
    line-height: 1.3;
    margin-top: 0;
  }
`;

const Price = styled.span`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  line-height: 1;

  @media (${mediaQuery('desktop')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[48]};
  }
`;

const VAT = styled.span`
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  line-height: 1.3;
`;

const MobileDescription = styled.div`
  @media (${mediaQuery('tablet')}) {
    margin: 0 auto;
    width: 500px;
  }
  @media (${mediaQuery('desktop')}) {
    display: none;
  }
`;

const DesktopDescription = styled.div`
  display: none;

  @media (${mediaQuery('desktop')}) {
    display: block;
  }
`;

const ExternalLink = styled.a`
  color: ${({ theme }) => theme.app.messages.action};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const HeroStyles = {
  Container,
  TrackerContainer,
  Half,
  Hero,
  SubTitle,
  Title,
  Description,
  List,
  Review,
  IconWrap,
  Cost,
  Hour,
  PricePrefix,
  Price,
  VAT,
  ImageWrap,
  PlumbingImageWrap,
  MobileDescription,
  DesktopDescription,
  ExternalLink,
};

export { HeroStyles };
