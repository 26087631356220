import { captureException } from '@sentry/browser';
// import jwtDecode from 'jwt-decode';

/*
 * Typedefs of the events currently being tracked. Expand these types (using |) as
 * tracking is ramped up to ensure consistent event names for analysis.
 */
type KantanPageEvent =
  | 'Portal-Booking page viewed'
  | 'Portal-Best slot modal viewed'
  | 'Portal-Something went wrong shown'
  | 'Portal-Landing page visited'
  | 'Portal-Service page viewed'
  | 'Portal-Private-Booking page viewed';
type KantanTrackEvent =
  | 'Portal-Job booked'
  | 'Portal-Job booking failed'
  | 'Portal-Best slot modal viewed'
  | 'Portal-Best slot modal dismissed';

type UTMInfo = {
  utm_campaign?: string | null;
  utm_medium?: string | null;
  utm_source?: string | null;
};

const getUtmInfo = (): UTMInfo => {
  try {
    const queryParams = new URLSearchParams(window.location.search);
    return {
      utm_campaign: queryParams.get('utm_campaign'),
      utm_source: queryParams.get('utm_source'),
      utm_medium: queryParams.get('utm_medium'),
    };
  } catch (err) {
    captureException(err);
    return {};
  }
};

function decodeAllComponents(url: string) {
  // ie ?,=,&,/ etc
  let param = url;
  while (param !== decodeURIComponent(param)) {
    param = decodeURIComponent(param);
  }
  return param;
}

export const urlWithUtmInfo = (url: string): string => {
  // Attaches the UTM information from this page's querystring to the
  // redirect url passed. Allows this to be sent up with the
  // 'Portal-Booking page viewed' event in analytics.js.
  const q = new URLSearchParams(window.location.search);
  const campaign = `?utm_campaign=${q.get('utm_campaign')}`;
  const source = `&utm_source=${q.get('utm_source')}`;
  const medium = `&utm_medium=${q.get('utm_medium')}`;
  const queryString = campaign + source + medium;
  return decodeAllComponents(url) + queryString;
};

// export const identifyUser = (token: string): void => {
//   try {
//     type Token = { sub: string };
//     const decodedToken: Token = jwtDecode(token);
//     const userId = decodedToken.sub;
//     if (userId) window.analytics.identify(userId);
//   } catch (err) {
//     captureException(err);
//   }
// };

export const trackPageView = (
  event: KantanPageEvent,
  properties = {},
): void => {
  if (typeof window === 'undefined') return;

  try {
    window.analytics.page(event, {
      ...properties,
      ...getUtmInfo(),
    });
  } catch (err) {
    captureException(err);
  }
};

export const trackEvent = (
  event: KantanTrackEvent,
  properties: Object,
): void => {
  try {
    window.analytics.track(event, {
      ...properties,
      ...getUtmInfo(),
    });
  } catch (err) {
    captureException(err);
  }
};
