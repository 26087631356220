import { useTheme } from '@emotion/react';
import { useLandingPage } from 'hooks/useLandingPage';

import { Button } from 'components/Button';

import { KantanLogo } from '../KantanLogo';
import { OvoLogo } from '../OvoLogo';
import { NavBarStyles as El } from './NavBar.styles';

export const NavBar = () => {
  const { exitAnimation, handleContactUsClick, isKantanPartner } =
    useLandingPage();
  const theme = useTheme();

  return (
    <El.NavBar exit={exitAnimation}>
      <El.Container>
        {isKantanPartner ? (
          <KantanLogo color={theme.app.messages.logo} width="120" />
        ) : (
          <OvoLogo color={theme.app.messages.logo} width="80" />
        )}
        <Button variant="shadow" onClick={handleContactUsClick}>
          Contact us
        </Button>
      </El.Container>
    </El.NavBar>
  );
};
