import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;

  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight['light']};
  color: ${({ theme }) => theme.app.text.secondary};

  a {
    cursor: pointer;
  }
`;

const TermsText = styled.section<{ $isOVO?: boolean }>`
  max-width: 1172px;
  padding: ${({ theme }) => theme.app.margin.largeMargin} 0
    ${({ theme, $isOVO }) => ($isOVO ? theme.app.margin.massiveMargin : '')};
  border-top: 1px solid #b9c0d0;
  border-bottom: 1px solid #b9c0d0;

  p {
    text-align: left;

    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    line-height: ${({ theme }) => theme.app.fontSettings.fontSize[20]};

    a {
      color: ${({ theme }) => theme.app.text.primary};
    }
  }
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  max-width: 1172px;
  height: fit-content;
  margin-top: ${({ theme }) => theme.app.margin.mediumLargeMargin};

  @media (${mediaQuery('tablet')}) {
    flex-direction: row;
    align-items: center;
  }
`;

const Logo = styled.div`
  @media (${mediaQuery('tablet')}) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
  }
`;

const CompanyInfoAndLinks = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  line-height: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
  color: ${({ theme }) => theme.app.text.primary};

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: fit-content;
    height: fit-content;
  }

  a {
    color: ${({ theme }) => theme.app.messages.action};
  }

  p.company-info {
    width: fit-content;
    text-align: start;

    color: ${({ theme }) => theme.app.text.tertiary};
  }

  @media (${mediaQuery('tablet')}) {
    flex-direction: row;
    justify-content: flex-end;

    div {
      justify-content: flex-end;
    }

    a,
    p.company-info {
      margin-left: ${({ theme }) => theme.app.margin.smallMargin};
    }
  }
`;

const FooterStyles = {
  Container,
  TermsText,
  BottomRow,
  Logo,
  CompanyInfoAndLinks,
};

export { FooterStyles };
