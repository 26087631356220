import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 1250px;
  margin: 0 auto;

  @media (${mediaQuery('tablet')}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: ${({ theme }) => theme.app.margin.mediumLargeMargin};
    padding: ${({ theme }) => theme.app.padding.extraLargePadding} 0;
  }
`;

const Description = styled.div<{ $compressed?: boolean }>`
  display: flex;
  margin-bottom: ${({ theme }) => theme.app.margin.extraExtraLargeMargin};
  align-items: ${({ $compressed }) =>
    $compressed === true ? 'center' : 'flex-start'};
  gap: ${({ theme }) => theme.app.margin.mediumMargin};

  @media (${mediaQuery('tablet')}) {
    margin-bottom: 0;
  }
`;

const Card = styled.div<{ $compressed?: boolean }>`
  width: 100%;
  display: flex;
  text-align: left;
  text-align: ${({ $compressed }) =>
    $compressed === true ? 'center' : 'left'};
  align-items: ${({ $compressed }) =>
    $compressed === true ? 'center' : 'flex-start'};
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: ${({ $compressed, theme }) =>
    $compressed === true ? theme.app.margin.massiveMargin : 0};

  &:last-child {
    margin-bottom: 0;
  }

  @media (${mediaQuery('tablet')}) {
    margin-bottom: 0;
  }

  @media (${mediaQuery('desktop')}) {
    padding: ${({ theme }) => theme.app.padding.mediumLargePadding};
  }
`;

const Title = styled.h4<{ $compressed?: boolean }>`
  display: flex;
  justify-content: ${({ $compressed }) =>
    $compressed === true ? 'center' : 'flex-start'};
  gap: ${({ theme }) => theme.app.margin.mediumMargin};
  width: 100%;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  color: ${({ theme, $compressed }) =>
    $compressed === true
      ? theme.app.messages.action
      : theme.app.landingPage.generalText.major};
  };
  margin-top: ${({ theme }) => theme.app.margin.mediumLargeMargin};
  margin-bottom: ${({ theme }) => theme.app.margin.mediumLargeMargin};
  

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  }
`;

const Text = styled.p`
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  color: ${({ theme }) => theme.app.landingPage.generalText.minor};
  line-height: 1.3;
  padding-left: 2.6rem;

  @media (${mediaQuery('tablet')}) {
    font-size: 22px;
  }
`;

const IconWrap = styled.div`
  min-width: 32px;
`;

const MainIconWrap = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.app.margin.mediumLargeMargin};
  text-align: center;
`;

const HighlightsStyles = {
  Container,
  Card,
  Description,
  Title,
  Text,
  IconWrap,
  MainIconWrap,
};

export { HighlightsStyles };
