import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

const Container = styled.div`
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  color: ${({ theme }) => theme.app.landingPage.generalText.major};
`;

const CTA = styled.div`
  width: 100%;
  text-align: center;

  button {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
    margin-bottom: ${({ theme }) => theme.app.margin.largeMargin};

    @media (${mediaQuery('tablet')}) {
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[32]};
    }
  }
`;

const Title = styled.h3`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  margin-bottom: ${({ theme }) => theme.app.margin.largeMargin};

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  }
`;

const SubText = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  line-height: 1.3;
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
  color: ${({ theme }) => theme.app.landingPage.generalText.minor};

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
  }
`;

const StartBookingStyles = {
  Container,
  CTA,
  Title,
  SubText,
};

export { StartBookingStyles };
