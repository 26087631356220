import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

const Container = styled.div<{ $fullVersion?: boolean }>`
  display: grid;
  width: 100%;
  max-width: 1000px;
  grid-template-columns: 1fr;
  margin: ${({ theme }) => theme.app.margin.extraExtraLargeMargin} auto;
  gap: ${({ theme }) => theme.app.margin.extraExtraLargeMargin};
  color: ${({ theme }) => theme.app.landingPage.generalText.major};

  @media (${mediaQuery('tablet')}) {
    grid-template-columns: 1fr 1fr;
  }

  ${({ $fullVersion }) =>
    $fullVersion &&
    `
     @media (${mediaQuery('tablet')}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
    `}
`;

const IconText = styled.div`
  text-align: center;

  p {
    max-width: 200px;
    margin: ${({ theme }) => theme.app.margin.mediumMargin} auto;
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    line-height: 1.3;

    @media (${mediaQuery('tablet')}) {
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
      max-width: 330px;
    }
  }
`;

const ImageWrap = styled.div`
  height: 50px;
`;

const GridIconsStyles = {
  Container,
  IconText,
  ImageWrap,
};

export { GridIconsStyles };
