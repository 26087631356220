import { useLandingPage } from 'hooks/useLandingPage';
import React, { PropsWithChildren, ReactNode } from 'react';

import { MarketingSectionStyles as MSS } from './MarketingSection.styles';

export type ColorScheme = 'dark' | 'light' | 'veryLight' | 'white' | 'muted';

interface Props {
  id?: string;
  title?: string | React.ReactElement;
  subTitle?: string | ReactNode;
  colorScheme?: ColorScheme;
  center?: boolean;
  reverse?: boolean;
  insetBackground?: boolean;
  paddingTop?: number;
  paddingBottom?: number;
  extendBackgroundColor?: boolean;
  responsivePadding?: boolean;
  actionTitle?: boolean;
  doNotUseHeadings?: boolean;
}

export const MarketingSection = ({
  children,
  id,
  title,
  subTitle,
  colorScheme = 'light',
  center = false,
  reverse = false,
  insetBackground = false,
  paddingTop = 80,
  paddingBottom = 40,
  actionTitle = false,
  extendBackgroundColor,
  responsivePadding = false,
  doNotUseHeadings = false,
}: PropsWithChildren<Props>) => {
  const { isKantanPartner } = useLandingPage();

  return (
    <MSS.Container
      id={id}
      $colorScheme={colorScheme}
      $paddingTop={paddingTop}
      $paddingBottom={paddingBottom}
      $responsivePadding={responsivePadding}
      data-testid="marketingSection"
    >
      <MSS.Content
        $reverse={reverse}
        $insetBackground={insetBackground}
        $extendBackground={extendBackgroundColor}
      >
        {!!title && (
          <MSS.Title
            as={doNotUseHeadings ? 'p' : 'h2'}
            $colorScheme={colorScheme}
            $actionTitle={actionTitle}
            $isKantan={isKantanPartner}
          >
            {title}
          </MSS.Title>
        )}
        {!!subTitle && (
          <MSS.SubTitle
            $colorScheme={colorScheme}
            $responsivePadding={responsivePadding}
          >
            {subTitle}
          </MSS.SubTitle>
        )}
        {!!children && center ? <MSS.Center>{children}</MSS.Center> : children}
      </MSS.Content>
    </MSS.Container>
  );
};
